/* General App Styles */
.App {
  font-family: Arial, sans-serif;
}

/* Header Styles */
.app-header {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1rem;
  border-bottom: 2px solid #900;
  width: 100%;
}

.sbu-logo {
  height: 50px;
  margin-right: 1rem;
}

.app-header h1 {
  color: #900;
  margin: 0;
}

/* Main Content Layout */
.main-content {
  display: flex;
  flex-direction: column;
}

/* Navigation Styles */
.side-nav {
  width: 100%;
  background-color: white;
  padding: 1rem;
  order: -1;
}

.side-nav h2 {
  color: #900;
  margin-top: 0;
}

.side-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.side-nav li {
  margin-bottom: 0.5rem;
  width: 48%;
}

.side-nav button {
  width: 100%;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #900;
  color: #900;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.side-nav button:hover {
  background-color: #900;
  color: white;
}

.active-filter {
  background-color: #ffcccc;
  font-weight: bold;
}

/* Content Area Styles */
.content-area {
  flex-grow: 1;
  width: 100%;
  margin: 0 auto; /* This centers the content */
  padding: 1rem;
}

/* Search Bar Styles */
.search-bar input {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Upload Form Toggle Button Styles */
.toggle-upload-form {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #900;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

/* Upload Form Styles */
.upload-form {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.upload-form input {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.upload-form button {
  padding: 0.5rem 1rem;
  background-color: #900;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

/* Course List Styles */
.course-list {
  margin-top: 1rem;
  background-color: #f0f0f0;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  align-items: center;
}

.course-item {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-left: 4px solid #900;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.course-info h3 {
  margin: 0;
  color: #900;
}

.course-info p {
  margin: 0.25rem 0;
}

.view-syllabus {
  background-color: #900;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 0.5rem;
  width: 100%;
}

.view-syllabus:hover {
  background-color: #600;
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-family: Arial, sans-serif;
}

.pagination-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0 1 rem;

}

.pagination-button {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  margin: 0 0.25rem;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #900;
  color: white;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.pagination-ellipsis {
  padding: 0.5rem 0.25rem;
  color: #666;
}

.pagination-button svg {
  vertical-align: middle;
}

.pagination span {
  margin: 0 0.5rem;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
  .main-content {
    flex-direction: row;
  }

  .side-nav {
    width: 200px;
    order: 0;
  }

  .side-nav ul {
    flex-direction: column;
  }

  .side-nav li {
    width: 100%;
  }

  .course-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .view-syllabus {
    width: auto;
    margin-top: 0;
  }

  .toggle-upload-form,
  .upload-form button {
    width: auto;
  }
}
